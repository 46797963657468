#home {
  position: relative;
  background: url("../../assets/bgIMG.png");
  background-size: cover;
  background-repeat: repeat;
  background-position: center;

  // disable the default padding styles
  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}
.app_header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;
  padding: 6rem 2rem 0rem;

  //when screen size more then 2000px
  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }
  //when screen size less then 1200px
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  //when screen size less then 450px
  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }

  
}

.app_header-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  margin: 0 2rem;
  //when screen size is more then 2000px
  @media screen and (max-width: 2000px) {
    width: 100%;
    margin-right: 0rem;
  }
}

.app_header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  .badge-cmp,
  .tag-cmp {
    padding: 1rem 1.5rem;
    background: var(--white-color);
    border-radius: 15px;
    flex-direction: row;
    width: auto;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  }

  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;
    p {
      width: 100%;
      text-transform: uppercase;
      text-align: right;
    }
    @media screen and (max-width: 800px) {
      margin-top: 2rem;
      margin-left: 8rem;
    }
  }
  span {
    font-size: 2.5rem;

    //for extra large screens
    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }
  @media screen and (min-width: 800px) {
   margin-top: 2rem;
  }
  //when screen size is smaller then 1200px
  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.app_header-circles {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  margin-left: 1rem;

  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    
    background: var(--white-color);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    img {
      width: 60%;
      height: 60%;
    }
  }
  //targetting first child
  div:nth-child(1) {
    width: 100px;
    height: 100px;
    background-color:rgba(255, 255, 0, 0.684);
  }
  //targetting second child
  div:nth-child(2) {
    margin: 1.75rem;
    width: 120px;
    height: 120px;
  }
  //targetting third child
  div:nth-child(3) {
    width: 70px;
    height: 70px;
  }
  //for larger screens
  @media screen and (min-width: 2000px) {
    div:nth-child(2) {
      width: 400px;
      height: 400px;
    }

    div:nth-child(3) {
      width: 170px;
      height: 170px;
    }

    div:nth-child(1) {
      width: 200px;
      height: 200px;
    }
  }
  //for screen with size less then 1200px
  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 1rem;
    }
  }
}

.app_header-img {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  // img {
  //   width: 100%;
  //   object-fit: contain;
  //   z-index: 1;
  // }
  .profile_image{
    width: 100%;
    z-index:1;
    background: url(../../assets/dp1.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow:inset 0 0 0 9px rgb(255 255 255/30%);
    justify-self: center;
    width: 400px;
    height: 400px;
    animation: profile_animation 7s ease-in-out infinite 1s;

    @media screen and (min-width: 800px) {
      margin-top: 2rem;
    }
    @media screen and (max-width: 800px) {
      width: 300px;
      height: 300px;
    }
  }
  @keyframes profile_animation{
    0%{
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
    50%{
      border-radius: 30% 60% 70% 40%/50% 60% 30% 60%
    }
    100%{
      border-radius:60% 40% 30% 70%/60% 30% 70% 40%;
    }
  }


  .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 90%;
    opacity: 0.2;
  }

  @media screen and (max-width: 1200px) {
    margin: 2rem 0;
  }
}

/*styles for number animations*/
.numbers_animation{
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0 2rem;
  //when screen size is more then 2000px
  @media screen and (min-width: 2000px) {
    width: 100%;
    margin-right: 0rem;
  }
  @media screen and (max-width:1200px) {
    margin-bottom: 10px;
  }
  @media screen and (max-width:1200px) {
    margin-bottom: 10px;
  }
  @media screen and (max-width:500px) {
    display: none;
  }
 
}
.numbers_header-badge{
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  .data{
    padding: 1rem 1.5rem;
   // background: var(--white-color);
    border-radius: 15px;
    flex-direction: row;
    gap:5px;
    width: auto;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    margin-top: 2rem;
  }
  @media screen and (max-width: 1200px) {
    .data{
      padding: 0.5rem 1rem;
      margin-top: 1rem;
    }
    justify-content:flex-start;
    align-items:center;
  }

}

.socialMediaLinks{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 15px;
  cursor: pointer;

  @media screen and (min-width:500px) {
    display: none;
  }

  a {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 0.5rem 0;
    border: 1px solid var(--lightGray-color);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;

    svg {
      width: 20px;
      height: 20px;
      color: var(--gray-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;

      margin: 0.5rem 0;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}