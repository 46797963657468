.app_blog {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
  .app_profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
  a{
    text-decoration: none;
    .app_profile-item {
      width: 250px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
      border-radius: 10px;
      flex-direction: column;
      margin: 1.5rem;
      
      &:hover {
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
      }
      img {
        width: 100%;
        height: 180px;
        border-radius:0px;
        object-fit: cover;
      }
      .blog_text{
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--primary-color);
        p{
          text-align: center;
        }
      }
      //when screen size is larger then 2000px
      @media screen and (min-width: 2000px) {
        width: 370px;
        margin: 2rem 4rem;
        img {
          height: 320px;
        }
      }
    }
  }


  .seemore {
    margin: 2rem 0 0 0;
    --text-color: #fff;
    position: relative;
    width: 11rem;
    border: none;
    background: #313bac;
    color: var(--text-color);
    padding: 1em;
    font-weight:600;
    font-family: var(--font-base);
    transition: 0.2s;
    border-radius: 5px;
    opacity: 0.9;
    letter-spacing: 2px;
    box-shadow: #171c61 0px 7px 2px, #000 0px 8px 5px;
  }
  
  .seemore:hover {
    opacity: 1;
    cursor: pointer;
  }
  
  .seemore:active {
    top: 4px;
    box-shadow: #171c61 0px 3px 2px,#000 0px 3px 5px;
  }