.app_skills {
  flex: 1;
  width: 100%;
  flex-direction: column;
  margin-top: 1rem;
}

.app_skills-container {
  width: 80%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //for screens less than 900px
  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
}
.app_skills-list {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content:center;

  @media screen and (max-width: 900px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }
}
.skill_circle{
  box-shadow: 0 0 20px rgba(0,0,0,0.5);
}
.app_skills-item {
    flex-direction: column;
    text-align: center;
    margin: 1rem;
    transition: all 0.3s ease-in-out;
    div {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: #fff;
  
      img {
        width: 70%;
        height: 70%;
      }
      //for screens more then 2000px of size
      @media screen and (min-width: 2000px) {
        width: 150px;
        height: 150px;
      }
      //for devices with less then 450px
      @media screen and (max-width: 450px) {
        width: 60px;
        height: 60px;
      }
    }
    @media screen and (max-width: 450px) {
     margin: 0.7rem;
    }
  
    p {
      font-weight: 600;
      margin-top: 0.5rem;
    }
  
    @media screen and (min-width: 2000px) {
      margin: 1rem 2rem;
  
      p {
        margin-top: 1rem;
      }
    }
  }
  
  .app_skills-exp {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-left:80px;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  
  .app_skills-exp-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1rem 0;
  }
  
  .app_skills-exp-works {
    flex: 1;
  
    a{
      text-decoration: none;
      .app_skills-exp-work {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem;
        cursor: pointer;
    
        h4 {
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 5px;
          transition: all 0.3s ease-in-out;
        }
    
        p {
          font-weight: 400;
          color: var(--gray-color);
          margin-top: 3px;
        }
        
      }
      &:hover{
        h4{
          color:var(--secondary-color);
        }
      }
    }
    
  }
  