.app_footer {
  flex: 1;
  width: 100%;
  flex-direction: column;
  margin-top: 2rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.app_footer-cards {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem 2rem ;

  .app_footer-card {
    min-width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--white-color);
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    svg {
      width: 40px;
      height: 40px;
      margin: 0 0.5rem;
    }

    p {
      font-weight: 700;
      color: black;
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
      gap: 5px;
      width: 45%;
      padding: 0.5rem;
      min-width: 150px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.app_footer-form {
  width: 60%;
  flex-direction: column;
  // margin: 0.5rem 2rem;

  div {
    width: 100%;
    margin: 0.5rem 0;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--white-color);

    transition: all 0.3s ease-in-out;

    input,
    textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;
      background-color: var(--white-color);

      font-family: var(--font-base);
      color: var(--secondary-color);
      outline: none;
    }

    textarea {
      height: 170px;
    }

    &:hover {
      box-shadow: 0 0 25px var(--white-color);
    }
  }

  // button {
  //   padding: 1rem 2rem;
  //   border-radius: 10px;
  //   border: none;
  //   background-color: var(--secondary-color);

  //   font-weight: 500;
  //   color: var(--white-color);
  //   outline: none;
  //   margin: 2rem 0 0 0;
  //   font-family: var(--font-base);

  //   transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  //   cursor: pointer;

  //   &:hover {
  //     background-color: #2430af;
  //   }
    
  // }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
}
.warning-text {
  color: red;
  font-size: 1rem;
}

.sendMessageBtn {
  margin: 2rem 0 0 0;
  --text-color: #fff;
  position: relative;
  width: 11rem;
  border: none;
  background: #313bac;
  color: var(--text-color);
  padding: 1em;
  font-weight: 600;
  transition: 0.2s;
  border-radius: 5px;
  opacity: 0.9;
  letter-spacing: 2px;
  box-shadow: #171c61 0px 7px 2px, #000 0px 8px 5px;
  font-family: var(--font-base);
}

.sendMessageBtn:hover {
  opacity: 1;
  cursor: pointer;
}

.sendMessageBtn:active {
  top: 4px;
  box-shadow: #171c61 0px 3px 2px,#000 0px 3px 5px;
}