.app_works {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app_work-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .app_work-item {
    width: 250px;
    flex-direction: column;

    margin: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    //cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.9);
    }

    @media screen and (min-width: 2000px) {
      width: 470px;
      padding: 1.25rem;
      border-radius: 0.75rem;
    }

    @media screen and (max-width: 300px) {
      width: 100%;
      margin: 1rem;
    }
  }
}

.app_work-img {
  width: 100%;
  height: 220px;

  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}

.app_work-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;

    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 50%;
      height: 50%;
      color: var(--white-color);
    }
  }
}

.app_work-content {
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;
  a {
    text-decoration: none;
  }
  h4 {
    margin-top: 0.5rem;
    line-height: 1;
    display: flex;
    &:hover {
      color: var(--secondary-color);
    }

    @media screen and (min-width: 2000px) {
      margin-top: 3rem;
    }
  }
  .description {
    text-align: center;
  }
}
.more_projects {
  margin: 1.5rem;
  p {
    font-size: 1.5rem;
    text-align: center;
  }
  .more_projects_list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap:25px;
    margin-top: 2rem;
    div {
      // width: 150px;
      
      .more_projects_title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        font-weight: 500;
        color: var(--black-color);
        
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
        &:hover {
          box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
         
          
        }
        @media screen and (max-width: 450px) {
          gap: 5px;
         padding: 0.5rem 0.5rem;
        }
      }

      @media screen and (min-width: 2000px) {
        width: 210px;
        margin: 2rem;
      }

      @media screen and (max-width: 800px) {
        display: none;
      }
    }
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
}

/***/
.more_projects_mobile{
  display: flex;
  gap: 25px;
  .btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--primary-color);
    transition: all 0.3s ease-in-out;

    svg {
      width: 30px;
      height: 30px;
      color: var(--secondary-color);
    }

    &:hover {
      background-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 100px;
      height: 100px;

      svg {
        width: 45px;
        height: 45px;
      }
    }
    @media screen and (max-width: 450px) {
      background-color: var(--white-color);
     svg{
      width: 40px;
      height: 40px;
     }
    }
  }
  .more_projects_mobile_list{
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      width:100%;
      .more_projects_title_mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        font-weight: 500;
        color: var(--black-color);
        
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
        &:hover {
          box-shadow: 0 0 25px rgba(0, 0, 0, 0.5); 
        }
        @media screen and (max-width: 450px) {
         gap: 10px;
        }
      }

      @media screen and (min-width: 2000px) {
        width: 210px;
        margin: 2rem;
      }

      @media screen and (max-width: 450px) {
        //width: 140px;
      }
    }
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
  @media screen and (max-width:450px){
    margin-top: -20px;
  }
  @media screen and (min-width: 800px) {
    display: none;
  }
}